import React from 'react';
import {
  FaInstagram,
  FaFacebookSquare,
  FaLinkedin,
  FaPaintBrush,
} from 'react-icons/fa';

const Footer = () => (
  <div className='footer'>
    <div className='footer-social'>
      <a
        href='https://www.instagram.com/allison_liffman_photography/?hl=en'
        target='_blank'
        rel='noreferrer'
      >
        <FaInstagram />
      </a>
      <a
        href='https://www.facebook.com/allisonliffmanphotography/'
        target='_blank'
        rel='noreferrer'
      >
        <FaFacebookSquare />
      </a>
      <a
        href='https://www.linkedin.com/in/allison-liffman-603ab716/'
        target='_blank'
        rel='noreferrer'
      >
        <FaLinkedin />
      </a>
    </div>
    <div className='art-site-link container'>
      <div className='columns'>
        <div className='column has-text-centered'>
          <h3>
            <a
              href='https://www.allisonliffmanart.com/'
              target='_blank'
              rel='noreferrer'
            >
              <FaPaintBrush className='contact-icon' />{' '}
              www.AllisonLiffmanArt.com
            </a>
          </h3>
        </div>
      </div>
    </div>
    <div className='footer-bottom'>
      <p>COPYRIGHT © 2020 Allison Liffman Photography | ALL RIGHTS RESERVED</p>
    </div>
  </div>
);

export default Footer;
