import React from 'react';
import Layout from '../components/layout';
import { graphql, StaticQuery } from 'gatsby';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import Footer from '../components/footer';

export default () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressPage(filter: { title: { eq: "About" } }) {
          edges {
            node {
              acf {
                image {
                  source_url
                }
                bio {
                  paragraphs
                }
                quote
              }
            }
          }
        }
      }
    `}
    render={(props) => (
      <Layout>
        <div className='about-body'>
          <div className='column is-8-widescreen is-offset-2-widescreen'>
            <div className='about-content-image'>
              <img
                alt='About'
                src={props.allWordpressPage.edges[0].node.acf.image.source_url}
              />
            </div>
            <div className='about-content-wrap'>
              {props.allWordpressPage.edges[0].node.acf.bio.map((item) => (
                <p>{item.paragraphs}</p>
              ))}
            </div>
            <br />
          </div>
        </div>
        <div className='quote-section column is-8-widescreen is-offset-2-widescreen'>
          <div className='columns'>
            <div className='quote-left'>
              <FaQuoteLeft className='quote' />
            </div>
            <div className='column is-10 quote-message'>
              <p>{props.allWordpressPage.edges[0].node.acf.quote}</p>
            </div>
            <div className='quote-right'>
              <FaQuoteRight className='quote' />
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    )}
  />
);
